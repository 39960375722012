const useSpecialMagicBox = () => {
	const startDate = new Date(Date.UTC(2024, 6, 15, 4, 0, 0));
	const endDate = new Date(Date.UTC(2024, 6, 22, 4, 0, 0));

	const { durationExceeded: exceededStart } = useCountdown({ timestamp: startDate.toString(), isOptimized: true });
	const { durationExceeded: exceededEnd } = useCountdown({ timestamp: endDate.toString(), isOptimized: true });

	const isSpecialBox = computed(() => exceededStart.value && !exceededEnd.value);

	return {
		isSpecialBox
	};
};

export default useSpecialMagicBox;
